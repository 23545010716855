<template>
    <div class="stage-inner-container-padded" v-if="fetchComplete">
        <div class="w-100 d-none d-print-flex flex-row record-export-header mt-2">
            <span>Report Exported Via </span> 
            <img class="small-logo my-0 p-0 mx-2" src="@/assets/images/logo/vai_logo.png" fluid alt="ValveAI Horizontal Logo"> 
            <span>Web UI on {{currentTimestamp}}</span>
        </div>
        <hr class="d-none d-print-block">
        <b-row>
            <b-col cols="12" md="6" lg="4" class="mt-3">
                <b-row>
                    <b-col cols="12">
                        <h2><b>Customer (Billing Info)</b></h2>
                    </b-col>
                    <b-col cols="12">
                        <b>Billing Contact: </b><br>
                        {{customer.first_name}} {{customer.last_name}} {{customer.company}}
                    </b-col>
                    <b-col cols="12">
                        <b>Billing Address: </b><br>
                        {{customer.billing_address_number}} {{customer.billing_address_predirection}} {{customer.billing_address_street_name}} {{customer.billing_address_street_suffix}} {{customer.billing_address_postdirectional}}
                        {{customer.billing_address_building}} {{customer.billing_address_unit}} {{customer.billing_address_extra}}<br>
                        {{customer.billing_address_line_two}}<br v-if="(customer.billing_address_line_two != null && customer.billing_address_line_two != '')">
                        {{customer.billing_address_city}}, {{customer.billing_address_state}} {{customer.billing_address_zip_code}}{{(customer.billing_address_zip_code_plus_four != null && customer.billing_address_zip_code_plus_four != '') ?  `-${customer.billing_address_zip_code_plus_four}` : ''}}
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" md="6" lg="4" class="mt-3">
                <b-row>
                    <b-col cols="12">
                        <h2><b>Site Info</b></h2>
                    </b-col>
                    <b-col cols="12" v-if="site.site_contact_first_name != null || site.site_contact_last_name != null">
                        <b>Site Contact: </b><br>
                        {{site.site_contact_first_name}} {{site.site_contact_last_name}}
                    </b-col>
                    <b-col cols="12" v-if="site.site_name != null">
                        <b>Site Name: </b><br>
                        {{site.site_name}}
                    </b-col>
                    <b-col cols="12" v-if="site.site_name != null">
                        <b>Site Type: </b>
                        {{replacement.site_type_value}}
                    </b-col>
                    <b-col cols="12">
                        <b>Service Address: </b><br>
                        {{site.service_address_number}} {{site.service_address_predirection}} {{site.service_address_street_name}} {{site.service_address_street_suffix}} {{site.service_address_postdirection}}
                        {{site.service_address_building}} {{site.service_address_unit}} {{site.service_address_extra}}<br>
                        {{site.service_address_line_two}}<br v-if="(site.service_address_line_two != null && site.service_address_line_two != '')">
                        {{site.service_address_city}}, {{site.service_address_state}} {{site.service_address_zip_code}}{{(site.service_address_zip_code_plus_four != null && site.service_address_zip_code_plus_four != '') ?  `-${site.service_address_zip_code_plus_four}` : ''}}
                    </b-col>
                    <b-col cols="12" v-if="site.facility_id != null">
                        <b>Facility ID: </b><br>
                        {{site.facility_id}}
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" md="6" lg="4" class="mt-3">
                <b-row>
                    <b-col cols="12">
                        <h2><b>Connection Info</b></h2>
                    </b-col>
                    <b-col cols="12">
                        <b>Summary: </b><br>
                        {{replacement.hist_connection_state_text}}
                    </b-col>
                    <b-col cols="12">
                        <b>Meter Info: </b>
                        {{connection.meter_size}}<span v-if="connection.meter_size != null">" </span>{{connection.meter_number}}
                    </b-col>
                    <b-col cols="12" v-if="connection.service_type_value != null">
                        <b>Service Type: </b>
                        {{connection.service_type_value}}
                    </b-col>
                    <b-col cols="12" v-if="connection.connection_type_value != null">
                        <b>Connection Type: </b>
                        {{connection.connection_type_value}}
                    </b-col>
                    <b-col cols="12" v-if="connection.utility_name != null">
                        <b>Utility: </b>
                        {{connection.utility_name}}
                    </b-col>
                    <b-col cols="12" v-if="connection.location_id != null">
                        <b>Location ID: </b>
                        {{connection.location_id}}
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" class="mt-3">
                <h2><b>BFPA Replacement</b></h2>
                <h4>Performed By: {{replacement.technician_name}}</h4>
                <h4>On: {{parseTimestampToDateTime(replacement.event_tstamp)}}</h4>
            </b-col>
        </b-row>
        <hr>
        <!-- TODO Condtion -->
        <b-row v-if="replacement.condition != null && replacement.condition.length != 0">
            <b-col cols="12">
                <h3><b>Condition</b></h3>
            </b-col>
            <hr>
            <b-col xs="12" sm="6" md="4" lg="3" xl="2" v-if="replacement.condition_photo != null">
                <h4><b>Condition Photo</b></h4>
                <hover-image-with-modal v-for="(photoID, index) in replacement.condition_photo" :key="'condition_photo_' + index" :imgID="photoID"></hover-image-with-modal>
            </b-col>
            <b-col xs="12" sm="6" md="4" lg="3" xl="2">
                <h4><b>Condition Info</b></h4>
                <b>Condition: </b> {{replacement.condition.join(", ")}} <br>
                <b>Notes: </b> {{replacement.condition_notes}} <br>
            </b-col>
            <hr>
        </b-row>
        <b-row>
            <b-col sm="12" lg="6">
                <h3><b>Previous Device</b></h3>
                <b-link v-b-popover.hover.left="'View This Device (Opens In New Tab)'" target="_blank" :to="getDeviceLink(replacement.replaces_device)">{{replacement.prev_dev_state_text}}</b-link>
            </b-col>
            <b-col sm="12" lg="6">
                <h3><b>New Device</b></h3>
                <b-link v-b-popover.hover.left="'View This Device (Opens In New Tab)'" target="_blank" :to="getDeviceLink(replacement.with_device)">{{replacement.new_dev_state_text}}</b-link>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col xs="12" sm="6" md="3" lg="3" xl="2" class="mt-2" v-if="replacement.overal_photo_before != null">
                <h4><b>Overall Before</b></h4>
                <hover-image-with-modal v-for="(photoID, index) in replacement.overal_photo_before" :key="'overal_photo_before_' + index" :imgID="photoID"></hover-image-with-modal>
            </b-col>
            <b-col xs="12" sm="6" md="3" lg="3" xl="2" class="mt-2" v-if="replacement.close_up_photo_before != null">
                <h4><b>Close Up Before</b></h4>
                <hover-image-with-modal v-for="(photoID, index) in replacement.close_up_photo_before" :key="'close_up_photo_before_' + index" :imgID="photoID"></hover-image-with-modal>
            </b-col>
            <b-col xs="12" sm="6" md="3" lg="3" xl="2" class="mt-2" v-if="replacement.photo_of_installed_bfpa_before_fill_dirt != null">
                <h4><b>Before Fill Dirt</b></h4>
                <hover-image-with-modal v-for="(photoID, index) in replacement.photo_of_installed_bfpa_before_fill_dirt" :key="'photo_of_installed_bfpa_before_fill_dirt_' + index" :imgID="photoID"></hover-image-with-modal>
            </b-col>
            <b-col xs="12" sm="6" md="3" lg="3" xl="2" class="mt-2" v-if="replacement.overall_photo_after != null">
                <h4><b>Overall After</b></h4>
                <hover-image-with-modal v-for="(photoID, index) in replacement.overall_photo_after" :key="'overall_photo_after_' + index" :imgID="photoID"></hover-image-with-modal>
            </b-col>
            <b-col xs="12" sm="6" md="3" lg="3" xl="2" class="mt-3">
                <h4><b>Notes</b></h4>
                <b>Verified Pressure: </b> {{booleanToTwoChoice(replacement.verified_pressure, "Yes", "No")}} <br>
                <b>Curbstop Condition: </b> {{booleanToTwoChoice(replacement.curbstop_condition, "Curbstop Closed", "")}} <br>
                <b>Outlet Connection Info: </b> {{replacement.outlet_connection_info.join(", ")}} <br>
                <b>Event Notes: </b> {{replacement.event_notes.join(", ")}} <br>
            </b-col>
        </b-row>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');

const butils = require('../../libs/basicUtils.js')

import Image from '@/components/subcomponents/Image.vue';

export default {
    name: 'viewbfpareplacement',
    props: {
        eventID: {
            type: String
        },
    },
    components: {
        'hover-image-with-modal': Image,
    },
    data(){
        return{
            apiEndpoint: 'events/replacement/',
            replacement: {},
            customer: {},
            site: {},
            connection: {},
            fetchComplete: false,
            currentTimestamp: null,
        }
    },
    methods: {
        booleanToTwoChoice(input, optionYes, optionNo){
            if(input == true){
                return optionYes
            }else{
                return optionNo
            }
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: 3000,
                appendToast: true
            })
        },
        parseParentType(input){
            switch (input) {
                case 'customers':
                    return "Customer";
                case 'sites':
                    return "Sites";
                case 'connections':
                    return "Connection";
                case 'devices':
                    return "Device";
                default:
                    return "";
            }
        },
        parseTimestampToDateTime(input){
            return butils.formatters.timestampToDateTime(input, true, false, '\r\n ');
        },
        parseTimestampToDate(input){
            return butils.formatters.timestampToDateTime(input);
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: 3000,
                appendToast: true
            })
        },
        showFormForItem(item){
            var thisFormType = item.is;
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: thisFormType});
            if(defaultFormForType == null){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else if(defaultFormForType.length == 0){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else{
                this.$router.push({path: '/home/form/' + defaultFormForType[0].id + '/' + item.id })
            }
        },
        getDeviceLink(deviceID){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: 'devices'});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + deviceID
            }
        }
    },
    watch: {
    },
    beforeMount(){
        var date = new Date();
        this.currentTimestamp = butils.formatters.timestampToDateTime(date.toISOString(), true, true)
        instance.get(process.env.VUE_APP_API_BASE_URL + '/' + this.apiEndpoint + this.eventID + '.json')
            .then(async (response) => {
                this.replacement = {};
                this.replacement = response.data.result.records[0];
                this.customer = this.replacement.cust_json;
                this.site = this.replacement.site_json;
                this.connection = this.replacement.hist_connection_json;
                if(this.replacement.condition == null){
                    this.replacement.condition = [];
                }
                if(this.replacement.outlet_connection_info == null){
                    this.replacement.outlet_connection_info = [];
                }
                if(this.replacement.event_notes == null){
                    this.replacement.event_notes = [];
                }
                this.fetchComplete = true;
            })
            .catch((error) => {
                console.log(error);
                this.fetchComplete = true;
            });
    },
}
</script>

<style scoped>
.larger-header{
    font-size: 1.5em;
}
.small-logo{
    height: 1.5rem;
}
.record-export-header{
    font-size: 1.65rem;
    line-height: 1.65rem;
    height: 1.65rem;
}
</style>
